var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"white--text flex-nowrap",attrs:{"color":"#0aabbe","height":"150","tile":""},on:{"click":function($event){$event.stopPropagation();return _vm.listar.apply(null, arguments)}}},'v-card',attrs,false),on),[_c('v-card-title',[_vm._v(" Acompanhe os seus registros ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" Meus Registros ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"outlined":"","icon":""},on:{"click":_vm.listar}},[_c('v-icon',[_vm._v("mdi-update")])],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"outlined":"","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.lista_1_array,"item-key":"name","search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatData")(item.created_at))+" ")]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.ver",fn:function(ref){
var item = ref.item;
return [_c('Registro',{attrs:{"current-item":item}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('Edit',{attrs:{"current-item":item}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }