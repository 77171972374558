<template>
  <v-dialog
      v-model="dialog"
      :retain-focus="false"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-card
          v-bind="attrs"
          v-on="on"
          color="#0aabbe" 
          class="white--text flex-nowrap"
          height="150"
          @click.stop="listar"
          tile
          
      >
          <v-card-title>
            Acompanhe os seus registros
          </v-card-title>
          
      </v-card>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="10">

            Meus Registros
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn 
                outlined 
                icon
                @click="listar"
            >
              <v-icon>mdi-update</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn 
                outlined 
                icon
                @click="close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="lista_1_array"
        item-key="name"
        class="elevation-1"
        :search="search"
				:loading="loading"
      >
      <template v-slot:[`item.created_at`]="{ item }" >
        {{ item.created_at | formatData}}
      </template>
      <template v-slot:top>
        <v-text-field
        v-model="search"
        label="Pesquisar"
        class="mx-4"
        ></v-text-field>
      </template>   
        <template v-slot:[`item.ver`]="{ item }">
          <Registro :current-item="item" />
        </template>  
      <template v-slot:[`item.edit`]="{ item }">
        <Edit :current-item="item" />          
      </template>      
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
/* implementar nesta tela, dados do usuario para sua garantia, assim como dados de prazos e regras. */
export default {
  name:'ListaSugestoes',
  components:{
    Registro:()=>import('./Registro.vue'),
    Edit:()=>import('./Edit.vue')
  },    
  data:()=>({
    dialog:false,
    loading:false,
    tab: null,
    search: '',
    cfg:{},
    headers:[],
    usuario:JSON.parse(localStorage.getItem('cfgu')),
    
  }),
  computed:{
    ...mapState(['count_grupos_sugestao']),
    ...mapGetters(['ouvidoria','adm','ava','lista_1_array']),
    atendidos(){
      return  this.count_grupos_sugestao.num_sugestoes - this.count_grupos_sugestao.num_sugestoes_status_default 
    },
    isActive(){
      return true
    }
  },
  methods:{
    close(){
      this.dialog = false
    },
    ...mapActions(['loadConfig']),
    
    
    listar(){
      this.$store.dispatch('lista',{
        method: 'get',
        url: 'informe/ouvidorias/',
        instancia:'lista_1_array'
      })
      this.loading=false
    },
    get_count_sugestoes(){
      this.$store.dispatch('count_grupos_sugestao',{url:"informe/sugestoes/?grupo=grupo"})
    }

  },
  mounted(){
    this.get_count_sugestoes()
    this.listar()
    this.$store.dispatch('adm', this.usuario)
    this.$store.dispatch('ava', this.usuario)
    // this.$store.dispatch('ouvidoria', this.usuario)
    this.headers = [
      { text: '#',align: 'start',sortable: true, value: 'id',},
      { text: 'Tipo',align: 'start',sortable: true, value: 'tipo.text',},
      { text: 'Registro',align: 'start',sortable: true, value: 'registro',},
      { text: 'Quando',align: 'end',sortable: true, value: 'created_at'},
      { text: 'Resultado',align: 'end',sortable: true, value: 'resultado.text'},
      { text: 'Ver',align: 'center',sortable: true, value: 'ver'},
    ]
    // if(this.ouvidoria){
    //   this.headers = [
    //     { text: '#',align: 'start',sortable: true, value: 'id',},
    //     { text: 'Tipo',align: 'start',sortable: true, value: 'tipo.text',},
    //     { text: 'Registro',height:'50', class:'text-no-wrap',align: 'start',sortable: true, value: 'registro',},
    //     { text: 'Quando',align: 'end',sortable: true, value: 'created_at',},
    //     { text: 'Resultado',align: 'start',sortable: true, value: 'resultado.text',},
    //     { text: 'Ver',align: 'center',sortable: true, value: 'ver'},
    //     { text: 'Parecer',align: 'center',sortable: true, value: 'edit'},
    //   ]
    // }else{
    //   this.headers = [
    //     { text: '#',align: 'start',sortable: true, value: 'id',},
    //     { text: 'Tipo',align: 'start',sortable: true, value: 'tipo.text',},
    //     { text: 'Registro',align: 'start',sortable: true, value: 'registro',},
    //     { text: 'Quando',align: 'end',sortable: true, value: 'created_at'},
    //     { text: 'Resultado',align: 'end',sortable: true, value: 'resultado.text'},
    //     { text: 'Ver',align: 'center',sortable: true, value: 'ver'},
    //   ]
    // }
  }
}
</script>